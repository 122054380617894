<template>
  <div class="page_bg" style="margin:0;">
    <div class="search_top  flex-c-s-c" style="position: fixed;top:0;z-index:999;background: #ffffff;width:100%">
      <div class="ser_search  flex-r-sb-c">
        <div />
        <van-icon name="arrow-left" size="0.5rem" @click="backPath" />
        <van-search v-model="searchValue" class="classify_ser" placeholder="请输入搜索内容" shape="round" @search="inputSer"
                    @focus="onFocus" @input="inputWord" @clear="clearInput"
        >
          <template #right-icon>
            <van-button round color="#0767AB" size="small" style="width:1.49rem;height:0.69rem;margin-top:-0.1rem"
                        @click="inputSer"
            >搜索</van-button>
          </template>
        </van-search>
        <div />
      </div>
    </div>

    <!-- 展示搜索页面 -->
    <div v-if="showMore" class="show_ser_more" style="margin-top:1.6rem">
      <div v-for="(more,more_index) in wordList" :key="more_index" class="word_item flex-c-c-c">
        <div class="word_box flex-r-s-s" @click="inputSerWord(more)">
          <van-icon name="search" size="0.5rem" />
          <div class="word van-ellipsis"
               v-html="parseInt(more.type) === 2 ? more.proName : more.searchTagNameHighLight"
          />
        </div>
        <div class="diver_word" />
      </div>
    </div>
    <div v-else>
      <!-- 顶部过滤 -->
      <serTopFilter ref="serTopFilter" :type="2" :name="searchValue" style="margin-top:1.8rem"
                    @changeFilter="changeFilter" @changeFiltercategory="changeFiltercategory"
                    @changeFilterbrand="changeFilterbrand"
      />
      <!-- 商品列表区域  -->
      <div class="pro_list">
        <MyscrollList ref="myList01" :loading="listLoading" :list="proList" @myload="proload">
          <template #default="slotProps">
            <proCard01 :info="slotProps.item" />
          </template>
        </MyscrollList>
      </div>
    </div>
  </div>
</template>

<script>
import './index.scss'
import { mallProduct_searchTagList } from '@/api/pro'
import { mallProduct_searchMallProduct } from '@/api/home'
import MyscrollList from '@/components/MyscrollList'
import proCard01 from '@/components/pro_card_01'
import serTopFilter from '@/components/ser_top_filter'
export default {
  components: { MyscrollList, proCard01, serTopFilter },
  data() {
    return {
      searchValue: '',
      historyList: JSON.parse(localStorage.getItem('historySer')) || [],
      // 商品列表
      proSearch: { pageNum: 1, pageSize: 10, proName: '', searchTagId: '' },
      proList: [],
      listLoading: false,

      showMore: false,
      wordList: []
    }
  },
  created() {
    if (this.$route.query.type) {
      this.searchValue = this.$route.query.val
      this.proSearch.proName = parseInt(this.$route.query.type) === 2 ? this.$route.query.val : ''
      this.proSearch.searchTagId = parseInt(this.$route.query.type) === 2 ? '' : this.$route.query.searchTagId
    } else {
      this.searchValue = this.$route.query.name
      this.proSearch.proName = this.$route.query.name
    }
    this.getProList()
  },
  activated() {
    console.log('keepalive 进入brandList')
    if (sessionStorage.getItem('listJump')) {
      window.scrollTo({
        top: 0, // 滚动到顶部
        behavior: 'smooth' // 平滑滚动
      })
      sessionStorage.removeItem('listJump')
      this.proSearch.proName = this.$route.query.name
      this.getProList()
    }
  },
  deactivated() {
    this.$refs.serTopFilter.closePullBox()
    console.log('keepalive 离开brandList')
  },
  methods: {
    backPath() { window.history.back() },
    getProList() {
      this.$toast.loading({ message: '加载中...', forbidClick: true, loadingType: 'spinner', duration: 0 })
      mallProduct_searchMallProduct(this.proSearch).then(res => {
        this.$refs.myList01.load_empty('list', false)
        this.$toast.clear()
        this.$refs.myList01.load_loading(false)
        this.proList = this.proList.concat(res.data.data)
        if (parseInt(res.data.total) === 0) { this.$refs.myList01.load_empty('list', true) } else {
          if (res.data.data.length === 0) { this.$refs.myList01.load_finished(true) } // 数据全部加载完成
        }
      })
    },
    proload() {
      this.$refs.myList01.load_loading(true)
      this.$utils.debounce(() => {
        this.proSearch.pageNum = this.proSearch.pageNum + 1
        this.getProList()
        // 加载状态结束
      }, 50)()
    },
    // 顶部搜索
    changeFilter(e) {
      console.log(e)
      this.proSearch.pageNum = 1
      if (e === 1 || e === 41) {
        this.proSearch.orderType = ''
        this.proSearch.orderWay = ''
        this.proSearch.tagId = ''; this.proSearch.proBrand = ''; this.proSearch.proCategoryId = ''
      }
      if (e === 2) {
        this.issaleNum = !this.issaleNum
        this.proSearch.orderType = 'SALE_NUM'
        this.proSearch.orderWay = this.issaleNum ? 'DESC' : 'ASC'
        this.proSearch.tagId = ''; this.proSearch.proBrand = ''; this.proSearch.proCategoryId = ''
      }
      if (e === 3) {
        this.isprice = !this.isprice
        this.proSearch.orderType = 'PRICE'
        this.proSearch.orderWay = this.isprice ? 'DESC' : 'ASC'
        this.proSearch.tagId = ''; this.proSearch.proBrand = ''; this.proSearch.proCategoryId = ''
      }
      if (e === 42) {
        this.proSearch.orderType = ''; this.proSearch.orderWay = ''; this.proSearch.proBrand = ''; this.proSearch.proCategoryId = ''
        this.proSearch.tagId = '282'
      }
      if (e === 43) {
        this.proSearch.orderType = ''; this.proSearch.orderWay = ''; this.proSearch.proBrand = ''; this.proSearch.proCategoryId = ''
        this.proSearch.tagId = '281'
      }
      if (e === 44) {
        this.proSearch.orderType = ''; this.proSearch.orderWay = ''; this.proSearch.proBrand = ''; this.proSearch.proCategoryId = ''
        this.proSearch.tagId = '275'
      }
      this.proList = []
      this.getProList()
    },
    changeFiltercategory(id) {
      this.proSearch.pageNum = 1
      this.proSearch.orderType = ''; this.proSearch.orderWay = ''; this.proSearch.proBrand = ''
      this.proSearch.tagId = ''; this.proSearch.proCategoryId = id
      this.$nextTick(() => {
        this.proList = []
        this.getProList()
      })
    },
    changeFilterbrand(brand) {
      this.proSearch.pageNum = 1
      this.proSearch.orderType = ''; this.proSearch.orderWay = ''; this.proSearch.proBrand = brand
      this.proSearch.tagId = ''; this.proSearch.proCategoryId = ''
      this.$nextTick(() => {
        this.proList = []
        this.getProList()
      })
    },
    // 获焦事件
    onFocus() {
      this.showMore = false
      this.$refs.serTopFilter.closePullBox()
      this.$refs.myList01.load_status_clear()
      // this.searchValue = ''
      this.proSearch.searchTagId = ''
      this.proList = []
      this.proSearch.pageNum = 1
    },
    clearInput() {
      this.showMore = false
      this.$refs.serTopFilter.closePullBox()
      this.$refs.myList01.load_status_clear()
      this.searchValue = ''
      this.proSearch.searchTagId = ''
      this.proList = []
      this.proSearch.pageNum = 1
      this.proSearch.proName = this.searchValue
    },
    inputSer() {
      this.showMore = false
      this.$nextTick(() => {
        this.$refs.myList01.load_status_clear()
        this.$refs.serTopFilter.closePullBox()
        this.proSearch.searchTagId = ''
        this.proList = []
        this.proSearch.pageNum = 1
        this.proSearch.proName = this.searchValue
        this.getProList()
      })
    },
    // 存localstrong 事件
    setHistory(e) {
      for (var i = 0; i < this.historyList.length; i++) {
        if (this.historyList[i] === e) { this.historyList.splice(i, 1) }
      }
      this.historyList.unshift(e)
      localStorage.setItem('historySer', JSON.stringify(this.historyList))
    },
    inputSerWord(item) {
      if (item.proName === '贝思倍健' || item.searchTagName === '贝思倍健') {
        this.$router.push('/product/subjectPage_bsbj')
      } else {
        const val = parseInt(item.type) === 2 ? item.proName : item.searchTagName
        this.setHistory(val)
        this.searchValue = val
        this.proSearch.pageNum = 1
        this.proSearch.proName = parseInt(item.type) === 2 ? item.proName : ''
        this.proSearch.searchTagId = parseInt(item.type) === 2 ? '' : item.searchTagId
        this.$nextTick(() => {
          this.wordList = []
          this.showMore = false
          this.proList = []
          this.getProList()
        })
      }
    },
    inputWord(e) {
      if (e.length >= 1) {
        mallProduct_searchTagList({ pageNum: 1, pageSize: 999, keyWords: e }).then(res => {
          this.wordList = res.data.data
        })
        this.showMore = true
      } else {
        this.wordList = []
        this.showMore = false
      }
    }
  }
}
</script>

<style>
</style>
